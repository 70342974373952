<template>
  <div>
    <div
      v-for="(infoBlock, index) in infoBlocks"
      :key="index"
      class="mb-5 last:mb-0"
    >
      <div class="my-2">
        <div ref="text" :class="truncateClasses">
          <rich-text-renderer
            :document="infoBlock.description"
            parent-component="info-card"
          />
        </div>
        <a
          v-if="!noTruncate && isContentOverflow"
          class="text-link hover:underline cursor-pointer m-0"
          :class="[{ 'hidden sm:block': noTruncateMobile }]"
          data-test="card-info-link"
          @click.prevent="onToggleContent"
        >
          {{ $t(showMoreTranslateKey) }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { checkOverflow } from '@/utils/checkOverflow'
import { RichTextRenderer } from '~/components/renderers'

export default {
  components: {
    RichTextRenderer,
  },
  props: {
    infoBlocks: {
      type: Array,
      required: true,
    },
    noTruncate: { type: Boolean, default: false },
    noTruncateMobile: { type: Boolean, default: false },
  },
  data() {
    return {
      showAll: false,
      isContentOverflow: false,
    }
  },
  computed: {
    truncateClasses() {
      const truncateClass =
        this.showAll || this.noTruncate
          ? ''
          : 'truncate-5-lines md:truncate-3-lines'

      return this.noTruncateMobile ? `sm:${truncateClass}` : truncateClass
    },
    showMoreTranslateKey() {
      return this.showAll ? 'general.show_less' : 'general.show_more'
    },
  },
  mounted() {
    // Refs aren't available until next tick due to async behaviour
    this.$nextTick(() => {
      this.setContentOverflow()
    })
  },
  methods: {
    onToggleContent() {
      this.showAll = !this.showAll
    },
    setContentOverflow() {
      // only display read more button if content in rich text renderer is overflowing i.e. shows ...
      this.isContentOverflow = checkOverflow(this.$refs.text?.[0])
      this.showAll = false
    },
  },
}
</script>
